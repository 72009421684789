import styled from 'styled-components';
import { Form } from 'formik';

import closeModal from '../../../images/closeModal.svg';

export const RenewModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  justify-content: center;
  align-items: center;
  background: white;
  opacity: 1;
  z-index: 100;
  border-radius: 5px;
  position: relative;
  @media (min-width: 768px) {
    padding: 23px 42px;
  }
`;

export const CloseModalButton = styled.div`
  background: url(${closeModal});
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-size: cover;

  @media (min-width: 768px) {
    width: 14px;
    height: 14px;
  }
`;

export const Modal = styled.div`
  height: 100%;
  width: 310px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  outline: none;
  @media (min-width: 360px) {
    width: 350px;
  }
  @media (min-height: 500px) {
    max-height: 520px;
    overflow-y: none;
  }

  @media (min-width: 768px) {
    width: 750px;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DomainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  @media (min-width: 768px) {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 20px;
  }
`;

export const Domain = styled.div`
  font-size: 18px;
  font-weight: normal;
  align-self: center;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
export const Renew = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 25px;
  @media (min-width: 768px) {
    padding-top: 50px;
  }
`;
export const RenewHeader = styled.div`
  font-size: 18px;
  font-weight: normal;
  padding: 10px 0px;
  align-self: flex-start;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
export const Select = styled.select`
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-size: 18px;
`;

export const Option = styled.option`
  font-weight: normal;
  font-size: 18px;
  color: #000000;
  opacity: 0.87;
`;

export const RenewButton = styled.button`
  background: #00c084;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  font-weight: normal;
  opacity: 0.87;
  padding: 9px 0px;
  border: none;
  transition: 0.3s;
  @media (min-width: 768px) {
    font-size: 20px;
    padding: 11px 0px;
  }
`;
