import React, { useRef } from 'react'

import * as S from './SettingsPopup.styles'
import { RedirectToCpanelModal } from '../../../../../../components/Modals/RedirectToCpanelModal'
import { CsrModal } from '../../../../../../components/Modals/CsrModal'
import { VpsModal } from '../../../../../../components/Modals/VpsModal'
import { useOnClickOutside } from '../../../../../../hooks/useOnClickOutside'
import { i18n } from '../../../../../../i18n'
import { Link } from 'react-router-dom'
import { changeUrl } from '../../../../../../hooks/changeUrl'
import { useHistory } from 'react-router'

export const SettingsPopup = ({
  showSettingsPopup,
  isShown,
  popupId,
  productId,
  showModal,
  isCSRConfigured,
  isHosting,
  isVps,
  serviceid,
  awaiting,
  orderId,
  // configs,
  id,
}) => {
  const history = useHistory()
  const containerRef = useRef(null)
  useOnClickOutside(containerRef, () => showSettingsPopup(false))
  if (isShown !== popupId) {
    return null
  }

  // localStorage.setItem('id', id)
  // localStorage.setItem('orderid', orderId)

  return (
    <S.SettingsPopup ref={containerRef}>
      {isHosting && (
        <S.Status
          onClick={() =>
            showModal(RedirectToCpanelModal, { hostingId: productId })
          }
        >
          {i18n.t('settingsPopup.enterCpanel')}
        </S.Status>
      )}
      {/* {(isCSRConfigured === 0 || isCSRConfigured === 1) && (
        <S.Status
          onClick={() => showModal(CsrModal, { productId, largeModal: true })}
        >
          {i18n.t('settingsPopup.csrModal')}
        </S.Status>
      )} */}
      {isVps && (
        <S.Status onClick={() => showModal(VpsModal, { serviceid: productId })}>
          {i18n.t('settingsPopup.vps')}
        </S.Status>
      )}
      {id && !isHosting && !isVps && (
        <S.Status>
          <Link
            to={changeUrl(`/service-details/${id}`)}
            style={{ textDecoration: 'none', color: '#242e46' }}
          >
            {i18n.t('settingsPopup.details')}
          </Link>
        </S.Status>
      )}
      {awaiting && (
        <S.Status>
          <Link
            to={changeUrl(`/ssl-configuration/${orderId}/${id}/1`)}
            target="_blank"
          >
            {i18n.t('settingsPopup.configure')}
          </Link>
        </S.Status>
      )}
    </S.SettingsPopup>
  )
}
