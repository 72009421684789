import React from 'react'

import * as S from './ServicesTable.styles'
import { i18n } from '../../../../i18n'
import { headers } from './ServicesTable.constants'
import { FilterPopup } from '../FilterPopup'
import { Sort } from '../SortPopup'
import { MobileSortPopup } from '../MobileSortPopup'
import { SettingsPopup } from './components/SettingsPopup'
import { Loader } from '../../../../components/Loader'
import { Pagination } from '../Pagination'
import Cookies from 'js-cookie'

const ServicesTable = ({
  toggleFilterPopup,
  showSortPopup,
  data,
  total,
  statuses,
  showSettingsPopup,
  loading,
  numberWithCommas,
  filter,
  getServices,
  offsets,
  sortField,
  sortOrder,
  sortType,
}) => {
  if (loading) {
    return (
      <S.EmptyStateContainer>
        <Loader color={'#00C084'} size={0.5} />
      </S.EmptyStateContainer>
    )
  }
  if (!data.length && !filter) {
    return <S.EmptyStateContainer>No records found</S.EmptyStateContainer>
  }

  return (
    <>
      <S.CartBlock>
        <S.Headers>
          {headers.map((header) => (
            <S.Header key={header.id}>
              <S.Title>{i18n.t(header.title)}</S.Title>
              <Sort
                cb={getServices}
                sortType={header.sortType}
                sortNumber={header.sortNumber}
                sortBy={header.sortBy}
                sortOrder={sortField === header.sortBy && sortOrder}
                offset={offsets.services}
                sortField={sortField}
              />
            </S.Header>
          ))}
          <S.SortMin
            id="desktopSort"
            onClick={() => showSortPopup(headers.sortNumber, headers)}
          />
          <MobileSortPopup
            cb={getServices}
            fields={headers}
            sortBy={sortField}
            sortOrder={sortOrder}
            offset={offsets.services}
          />

          <FilterPopup options={statuses} filterType="services" />
          <S.Filter id="desktopFilter" onClick={toggleFilterPopup} />
        </S.Headers>
        {data.map((item) => (
          <S.CartContent key={item.id}>
            <S.ServiceData>
              <S.HeaderWrapper>
                <S.Package>{item.name}</S.Package>
                <S.Domain>{item.domain}</S.Domain>
              </S.HeaderWrapper>
              <S.Info am={Cookies.get('lng') === 'am'}>{item.regdate}</S.Info>
              <S.Info>
                {i18n.t(`backend.servicesBillCycle.${item.billingcycle}`)}
              </S.Info>
              <S.Info style={{ fontFamily: 'sans-serif' }}>
                {numberWithCommas(item.recurringamount)}
              </S.Info>
              <S.Info>
                {item.nextduedate === '0000-00-00' ? '' : item.nextduedate}
              </S.Info>
              <S.Status>
                {i18n.t(`backend.serviceStatuses.${item.status}`)}

                <S.SSLStatus>
                  {item.ssl_status &&
                    i18n.t(`sslConfiguration.${item.ssl_status}`)}
                </S.SSLStatus>
              </S.Status>
            </S.ServiceData>
            <S.MobileServiceData>
              <S.HeaderWrapper>
                <S.Domain>{item.domain}</S.Domain>
              </S.HeaderWrapper>
              <S.ServiceBlock>
                <S.Package>{item.name}</S.Package>
                <S.InfoBlock>
                  <S.InfoWrapper>
                    <S.ServiceBillCycle>
                      {i18n.t(`backend.servicesBillCycle.${item.billingcycle}`)}
                    </S.ServiceBillCycle>
                    <S.ServiceRegdate>{item.regdate}</S.ServiceRegdate>
                  </S.InfoWrapper>
                  <S.DataWrapper>
                    {item.nextduedate === '0000-00-00' ? '' : item.nextduedate}
                    <S.Status>
                      {i18n.t(`backend.serviceStatuses.${item.status}`)}
                    </S.Status>
                  </S.DataWrapper>
                </S.InfoBlock>
                <S.Price>{numberWithCommas(item.recurringamount)}</S.Price>
                <S.MobileSSLStatus style={{ fontSize: '14px' }}>
                  {item.ssl_status &&
                    i18n.t(`sslConfiguration.${item.ssl_status}`)}
                </S.MobileSSLStatus>
              </S.ServiceBlock>
            </S.MobileServiceData>
            <SettingsPopup
              orderId={item.ssl_order_id}
              awaiting={item.ssl_status === 'Awaiting Configuration'}
              popupId={item.id}
              productId={item.id}
              isCSRConfigured={item.csr_configured}
              isHosting={item.serverhostname}
              isVps={item.groupname === 'SSD Cloud Servers'}
              // configs={item.csr_configs}
              id={item.id}
            />

            {((item.type === 'service' && item.csr_configured >= 0) ||
              item.serverhostname ||
              item.groupname === 'SSD Cloud Servers') &&
              ![
                'Terminated',
                'Unpaid',
                'Suspended',
                'Cancelled',
                'Pending',
              ].includes(item.status) && (
                <S.CartSettingsIcon
                  onClick={() => showSettingsPopup(item.id)}
                />
              )}
          </S.CartContent>
        ))}
      </S.CartBlock>
      <Pagination
        goToPage={(offset) => getServices(offset, sortOrder, sortField)}
        totalResults={total}
        currentOffset={offsets.services}
      />
      {/* <S.BottomButtonsContainer>
        <S.ActionsButton>
          {i18n.t('clientAreaPage.actionButton')}
          <S.ArrowIcon />
        </S.ActionsButton>
        <S.AddServiceButton>
          {i18n.t('clientAreaPage.addServiceButton')}
        </S.AddServiceButton>
      </S.BottomButtonsContainer> */}
    </>
  )
}

export { ServicesTable }
