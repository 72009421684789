const config = [
  {
    tld: '.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.հայ',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.co.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.com.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.net.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.org.am',
    years: 3,
    canBeIncognito: true,
  },
  {
    tld: '.ru',
    years: 1,
    canBeIncognito: false,
    additionalFields: [
      {
        id: 'idNumbers',
        label: 'ID number*',
        subLabel: '*ID or Passport number',
      },
      {
        id: 'taxpayerIdentificationNumber',
        label: 'Taxpayer identification number:',
        subLabel: 'Only required for organizations',
      },
      {
        id: 'dateOfBirth',
        label: 'Date of birth:',
        subLabel: 'Only required for persons',
      },
    ],
  },
];

const initialValues = {
  years: 1,
};

export { config, initialValues };
