export const backendAm = {
  backend: {
    hostingStatuses: {
      Active: 'Ակտիվ',
      Expired: 'Ժամկետանց',
      Cancelled: 'Չեղարկված',
      Pending: 'Ընթացքի մեջ',
      'Pending Transfer': 'Տեղափոխումը սպասման մեջ',
      Grace: 'Վճարման արտոնյալ ժամանակաշրջան',
      'Transferred Away': 'Տեղափոխված',
    },
    wrongPass: 'Սխալ գաղտնաբառ',
    error_code: {
      blocked_account:
        'Օգտահաշվի մուտքն արգելափակված է սխալ գաղտնաբառ հավաքելու պատճառով։ Կարող եք կրկին փորձել 5 րոպեից։',
    },
    serviceStatuses: {
      Active: 'Ակտիվ',
      Terminated: 'Ջնջված',
      Cancelled: 'Չեղարկված',
      Pending: 'Սպասման մեջ',
      Suspended: 'Կասեցված',
    },
    ticketStatuses: {
      Open: 'Նոր',
      Closed: 'Փակված',
      'Customer-Reply': 'Հաճախորդի պատասխան',
      Answered: 'Պատասխանված',
      'In Progress': 'Ընթացքի մեջ',
    },
    invoiceStatuses: {
      Paid: 'Վճարված',
      Cancelled: 'Չեղարկված',
      Refunded: 'Վերադարձ',
      Draft: 'Սևագիր',
      Unpaid: 'Չվճարված',
    },
    filterItems: {
      Active: 'Ակտիվ',
      Expired: 'Ժամկետանց',
      Cancelled: 'Չեղարկված',
      Pending: 'Սպասման մեջ',
      Open: 'Նոր',
      Closed: 'Փակված',
      'Customer-Reply': 'Հաճախորդի պատասխան',
      Paid: 'Վճարված',
      Refunded: 'Վերադարձ',
      Draft: 'Սևագիր',
      Unpaid: 'Չվճարված',
      Terminated: 'Կասեցված',
      Suspended: 'Ջնջված',
      'Pending Transfer': 'Տեղափոխումը սպասման մեջ',
      Grace: 'Վճարման արտոնյալ ժամանակաշրջան',
      Answered: 'Պատասխանված',
      'In Progress': 'Ընթացքի մեջ',
    },
    servicesBillCycle: {
      Monthly: 'Ամսական',
      Quarterly: 'Եռամսյակային',
      'Semi-Annually': 'Կիսամյակային',
      Annually: 'Տարեկան',
    },
    logInError: {
      'whmcs-error-200-email-or-password-invalid':
        'Սխալ էլ․ փոստ կամ գաղտնաբառ',
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
      'email-is-not-verified': 'Էլ. փոստը հաստատված չէ',
    },
    forgotPassword: {
      'email-is-not-found': 'Էլ. փոստը չի գտնվել',
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
      'email-is-not-verified': 'Էլ. փոստը հաստատված չէ',
      '-email-must-be-a-valid-email': 'Էլ. փոստը պետք է լինի վավեր',
    },
    registrationPage: {
      'whmcs-error-200-the-email-address-you-entered-was-not-valid':
        'Դուք նշել եք սխալ էլ. հասցե',
      'whmcs-error-200-a-client-already-exists-with-that-email-address':
        'Ձեր մուտքագրած էլ. փոստի հասցեն արդեն գրանցված է',
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
      '-email-must-be-a-valid-email': 'Էլ. փոստը պետք է լինի վավեր',
      'legalcode-must-only-contain-alpha-numeric-characters':
        'ՀՎՀՀ-ն  պետք է պարունակի միայն այբբենական թվային նիշեր',
    },
    resetPassword: {
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
    },
    personalDetails: {
      'Failed to fetch': 'Ինչ-որ բան սխալ է',
    },
    addContact: {
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
      '-email-must-be-a-valid-email': 'Էլ. փոստը պետք է լինի վավեր',
    },
    security: {
      'Failed to fetch': 'Ինչ-որ բան սխալ է',
    },
    viewTicket: {
      'Failed to fetch': 'Ինչ-որ բան սխալ է',
    },
    openTicket: {
      'Failed to fetch': 'Ինչ-որ բան սխալ է',
    },
    addOrder: {
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
      'this-promocode-doesn-t-belong-to-you':
        'Նշված պրոմոկոդը կցված չէ ձեր հաշվին',
    },
    transferDomain: {
      'Failed to fetch': 'Ինչ-որ բան սխալ է',
    },
    contactOwnerModal: {
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
      'invalid-domain-name': 'Նշված դոմենը չունի իրավատեր',
      '-email-must-be-a-valid-email': 'Էլ. փոստը պետք է լինի վավեր',
    },
    uploadError: {
      'csr-fails-to-match-the-required-pattern':
        'Սխալ CSR: Խնդրում ենք կրկին մուտքագրել',
      'failed-to-fetch': 'Ինչ-որ բան սխալ է',
    },
    paymentFailed: {
      '001': 'Hello',
      '0-100': 'No payment attempts',
      '0-2000': 'Transaction declined since the card is in the blacklist',
      '0-2001':
        'Transaction declined since Client’s IP address is in the blacklist ',
      '0-20010':
        'Transaction declined since payment amount exceeded the limits set by the issuer bank',
      '0-2002':
        'Transaction declined since payment amount exceeded the limits (daily turnover limits of the merchant set by the acquiring bank or one card turnover limit of the merchant or one transaction limit of the merchant)',
      '0-2004': 'Payment through SSL without entering SVС data is forbidden ',
      '0-2005':
        'We failed to check the issuer’s signature, i.e. PARes was readable but was not signed correctly ',
      '0-2006': 'Issuer declined authentication ',
      '0-2007':
        'Card data registration timeout starting from the moment of payment registration (timeout will be in 20 minutes)',
      '0-2011':
        'Card is included in 3d secure but the issuer bank is not ready (at that moment) to execute 3ds of transaction',
      '0-2012': 'This operation is not supported',
      '0-2013': 'Payment attempts expired ',
      '0-2015': 'VERes of DS contain iReq, due to which payment was declined ',
      '0-2016':
        'Issuer bank is not ready (at that moment) to execute 3ds of transaction (for instance, the bank’s ACS does not function). We cannot define whether the card is included in 3d secure or not.',
      '0-2017': 'Declined due to 3DSec error',
      '0-2018':
        'Directory server Visa or MasterCard cannot be reached or “connection failed” error is received in response to request VeReq. This error is the result interaction of payment gateway and MPS servers due to their technical failures.',
      '0-2019':
        'Issuer’s PARes contains iReq, due to which payment was declined.',
      '0-9000': 'Transactions start ',
      '01': 'Order with the given number is already registered in the system ',
      '0100': 'Issuer bank has forbidden online card transactions',
      '01001':
        'At the moment of registering the transaction, i.e. when the card details are not entered yet',
      '0101': 'Card’s validity period has expired ',
      '0103':
        'No connection with the issuer bank, the merchant must call the issuer bank',
      '0104': 'Attempt to make a transaction via blocked account',
      '0107': 'It is necessary to call the issuer bank',
      '0109':
        'Merchant’s/*terminal ID is invalid (for completion and preauthorization with different IDs)',
      '0110': 'Transaction amount is invalid',
      '0111': 'Card number is invalid',
      '0116': 'Transaction amount exceeds the available account balance ',
      '0120':
        'Transaction declined since it is not allowed by the issuer. Payment network code: 57. Reason for declining the transaction should be clarified from the issuer.',
      '0121':
        'Attempt to make a transaction exceeding the daily limit set by the issuer bank',
      '0123':
        'Limit on the number of transactions is exceeded. Client has made maximum number of transactions within the limit and attempts to make one more transaction',
      '0125':
        'Invalid Card number. Attempt to refund an amount exceeding the hold, attempt to refund zero amount',
      '0151017': '3-D Secure connection error',
      '0151018': 'Processing timeout, failed to send',
      '0151019':
        'Processing timeout, sent but no response received from the bank ',
      '02001':
        'Fraudulent transaction ( according to processing or payment network)',
      '02003':
        'Merchant is not allowed to perform SSL (Not 3d-Secure/SecureCode) transactions ',
      '02005': 'Payment does not comply with the  3ds verification terms ',
      '0208': 'Card is lost ',
      '0209': 'Card limitations are exceeded',
      '0341014': 'RBS decline error',
      '0341041': 'Refund error',
      '05': 'Error in request parameters ',
      '071015': 'Incorrect card parameters input',
      '08204': 'Such order has already been registered (check by order number)',
      '09001': 'Internal code of RBS rejection',
      '0902': 'Cardholder attempts to make a forbidden transaction ',
      '0903':
        'Attempt to make a transaction in the amount exceeding the limit set by the issuer bank ',
      '0904': 'Error message format according to issuer bank ',
      '0907':
        'No connection with the issuer bank. Stand-in authorization is not allowed for the given card number (this mode means that the issuer cannot connect to the payment network, so the transaction is either in offline mode and is then going to be sent to the back office or will be declined) ',
      '0909':
        'General system failure fixed by the payment network or the issuer bank ',
      '0910': 'Issuer bank is not available ',
      '0913': 'Invalid transaction format according to the network',
      '0914':
        'Transaction is not found (while sending completion, reversal or refund request)',
      '0999':
        'Transaction authorization did not start. Declined due to fraud or 3dsec error.',
      '02': 'Order declined due to errors in payment details',
      '03': 'Unknown (forbidden) currency',
      '04': 'Required parameter of the request is missing ',
      '06': 'Unregistered OrderId',
      '07': 'System error',
      20: 'Incorrect Username and Password',
      30: 'Incorrect Value of Opque field of the initial request',
      550: 'System Error',
    },
    sslConfiguration: {
      new: 'Նոր',
      renew: 'Երկարաձգում',
    },

    telcellPaymenStatus: {
      NEW: 'Հաշիվը ներկայացված է, վճարված չէ',
      PAID: 'Հաշիվը բարեհաջող վճարված է',
      REJECTED: 'Հաշիվը չեղարկված է ստացողի կողմից',
      EXPIRED: 'Ժամկետանց հաշիվ',
      CANCELLED: 'Հաշիվը չեղարկված է աշխատակցի կողմից',
      CANCELLEDFORREPEAT: 'Հաշիվը չեղարկվել է վերաթողարկման համար',
    },

    telcellPaymenInvoice: {
      invoice: 'Հաշիվ',
    },

    telcellPaymenUnknownStatus: {
      unknownStatus: 'Վճարումը չի հաջողվել',
    },
  },
}
