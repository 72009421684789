// import { i18n } from '../../i18n';

import adv1 from '../../images/adv1.svg';
import adv2 from '../../images/adv2.svg';
import adv3 from '../../images/adv3.svg';
import adv4 from '../../images/adv4.svg';
import adv5 from '../../images/adv5.svg';
import adv6 from '../../images/adv6.svg';
import adv7 from '../../images/adv7.svg';
import adv8 from '../../images/adv8.svg';
import cmsLogo1 from '../../images/cmsLogo1.svg';
import cmsLogo2 from '../../images/cmsLogo2.svg';
import cmsLogo3 from '../../images/cmsLogo3.svg';
import cmsLogo4 from '../../images/cmsLogo4.svg';
import cmsLogo5 from '../../images/cmsLogo5.svg';
import cmsLogo6 from '../../images/cmsLogo6.svg';
import cmsLogo7 from '../../images/cmsLogo7.svg';
import CheckIcon from '../../images/check-icon.png';

export const HOSTING_TABS = {
  LINUX: 'linux',
  VPS: 'vps',
};

export const VPSPackages = [
  {
    id: 'ssd_cloud_25_gb',
    name: 'SSD Cloud 25 GB',
    params: [
      {
        key: 'RAM',
        value: '1024 MB',
      },
      {
        key: 'SSD',
        value: '25 GB',
      },
      {
        key: 'BW',
        value: '1.00 TB',
      },
    ],
    price: 4900,
  },
  {
    id: 'ssd_cloud_40_gb',
    name: 'SSD Cloud 40 GB',
    params: [
      {
        key: 'RAM',
        value: '2048 MB',
      },
      {
        key: 'SSD',
        value: '40 GB',
      },
      {
        key: 'BW',
        value: '2.00 TB',
      },
    ],
    price: 9900,
  },
  {
    id: 'ssd_cloud_60_gb',
    name: 'SSD Cloud 60 GB',
    params: [
      {
        key: 'RAM',
        value: '4096 MB',
      },
      {
        key: 'SSD',
        value: '60 GB',
      },
      {
        key: 'BW',
        value: '3.00 TB',
      },
    ],
    price: 19900,
  },
  {
    id: 'ssd_cloud_100_gb',
    name: 'SSD Cloud 100 GB',
    params: [
      {
        key: 'RAM',
        value: '8192 MB',
      },
      {
        key: 'SSD',
        value: '100 GB',
      },
      {
        key: 'BW',
        value: '4.00 TB',
      },
    ],
    price: 39900,
  },
  {
    id: 'ssd_cloud_200_gb',
    name: 'SSD Cloud 200 GB',
    params: [
      {
        key: 'RAM',
        value: '1024 MB',
      },
      {
        key: 'SSD',
        value: '25 GB',
      },
      {
        key: 'BW',
        value: '1.00 TB',
      },
    ],
    price: 79900,
  },
  {
    id: 'ssd_cloud_110_gb_10_tb_dedic',
    name: 'SSD Cloud 110 GB 10TB BW(dedicated)',
    params: [
      {
        key: 'RAM',
        value: '2048 MB',
      },
      {
        key: 'SSD',
        value: '40 GB',
      },
      {
        key: 'BW',
        value: '2.00 TB',
      },
    ],
    price: 59900,
  },
  {
    id: 'ssd_cloud_110_gb_20_tb_dedic',
    name: 'SSD Cloud 110 GB 20TB BW(dedicated)',
    params: [
      {
        key: 'RAM',
        value: '4096 MB',
      },
      {
        key: 'SSD',
        value: '60 GB',
      },
      {
        key: 'BW',
        value: '3.00 TB',
      },
    ],
    price: 119900,
  },
];

export const hostingPackages = [
  {
    id: 'starter',
    name: 'Starter',
    pid: '1',
    isFeatured: true,
    params: [
      {
        key: 'hostingPage.mails',
        value: 15,
        isFeatured: true,
      },
      {
        key: 'hostingPage.capacity',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.db',
        value: 1,
        isFeatured: true,
      },
      {
        key: 'hostingPage.space',
        value: '300 MB',
        isFeatured: true,
      },
      {
        key: 'hostingPage.subdomain',
        value: 100,
        isFeatured: false,
      },
      {
        key: 'hostingPage.addon',
        value: 0,
        isFeatured: false,
      },
      {
        key: 'hostingPage.alias',
        value: 0,
        isFeatured: false,
      },
    ],
    price: 700,
    price_usd: 1.9,
  },
  {
    id: 'advanced',
    name: 'Advanced',
    pid: '2',
    isFeatured: true,

    params: [
      {
        key: 'hostingPage.mails',
        value: 30,
        isFeatured: true,
      },
      {
        key: 'hostingPage.capacity',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.db',
        value: 2,
        isFeatured: true,
      },
      {
        key: 'hostingPage.space',
        value: '2 GB',
        isFeatured: true,
      },
      {
        key: 'hostingPage.subdomain',
        value: 150,
        isFeatured: false,
      },
      {
        key: 'hostingPage.addon',
        value: 1,
        isFeatured: false,
      },
      {
        key: 'hostingPage.alias',
        value: 1,
        isFeatured: false,
      },
    ],
    price: 1300,
    price_usd: 2.9,
  },
  {
    id: 'premium',
    name: 'Premium',
    pid: '3',
    isFeatured: true,

    params: [
      {
        key: 'hostingPage.mails',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.capacity',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.db',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.space',
        value: '5 GB',
        isFeatured: true,
      },
      {
        key: 'hostingPage.subdomain',
        value: 300,
        isFeatured: false,
      },
      {
        key: 'hostingPage.addon',
        value: 5,
        isFeatured: false,
      },
      {
        key: 'hostingPage.alias',
        value: 5,
        isFeatured: false,
      },
    ],
    price: 2400,
    price_usd: 5.9,
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    pid: '4',
    isFeatured: true,

    params: [
      {
        key: 'hostingPage.mails',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.capacity',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.db',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.space',
        value: '15 GB',
        isFeatured: true,
      },
      {
        key: 'hostingPage.subdomain',
        value: 500,
        isFeatured: false,
      },
      {
        key: 'hostingPage.addon',
        value: 100,
        isFeatured: false,
      },
      {
        key: 'hostingPage.alias',
        value: 100,
        isFeatured: false,
      },
    ],
    price: 4400,
    price_usd: 9.9,
  },
  {
    id: 'DNSManagement',
    name: 'DNS Management',
    isFeatured: false,
    pid: '132',
    params: [
      {
        key: 'hostingPage.mails',
        value: '0',
        isFeatured: true,
      },
      {
        key: 'hostingPage.capacity',
        value: 'hostingPage.unlimited',
        isFeatured: true,
      },
      {
        key: 'hostingPage.db',
        value: '0',
        isFeatured: true,
      },
      {
        key: 'hostingPage.space',
        value: '10 MB',
        isFeatured: true,
      },
    ],
    price: 420,
    price_usd: 1.4,
  },
];

export const colors = [
  {
    backgroundColor: '#FFCC02',
    color: '#FFFFFF',
  },
  {
    backgroundColor: '#595BA8',
    color: '#1D1D1F',
  },
  {
    backgroundColor: '#BA2644',
    color: '#FFFFFF',
  },
  {
    backgroundColor: '#F88800',
    color: '#FFFFFF',
  },
  {
    backgroundColor: '#239BCA',
    color: '#FFFFFF',
  },
];

export const hostingAdvList = [
  {
    id: 'adv1',
    description: 'hostingPage.hostingAdv1',
    image: adv1,
  },
  {
    id: 'adv2',
    description: 'hostingPage.hostingAdv2',
    image: adv2,
  },
  {
    id: 'adv3',
    description: 'hostingPage.hostingAdv3',
    image: adv3,
  },
  {
    id: 'adv4',
    description: 'hostingPage.hostingAdv4',
    image: adv4,
  },

  {
    id: 'adv5',
    description: 'hostingPage.hostingAdv5',
    image: adv5,
  },
  {
    id: 'adv6',
    description: 'hostingPage.hostingAdv6',
    image: adv6,
  },
  {
    id: 'adv7',
    description: 'hostingPage.hostingAdv7',
    image: adv7,
  },

  {
    id: 'adv8',
    description: 'hostingPage.hostingAdv8',
    image: adv8,
  },
];

export const logos = [
  {
    id: 'logo1',
    title: 'wordpress',
    image: cmsLogo1,
  },

  {
    id: 'logo3',
    title: 'laravel',
    image: cmsLogo3,
  },
  {
    id: 'logo4',
    title: 'joomla',
    image: cmsLogo4,
  },
  {
    id: 'logo2',
    title: 'Magneto',
    image: cmsLogo2,
  },
  {
    id: 'logo5',
    title: 'opencart',
    image: cmsLogo5,
  },
  {
    id: 'logo6',
    title: 'drupal',
    image: cmsLogo6,
  },
  {
    id: 'logo7',
    title: 'Bitrix 24',
    image: cmsLogo7,
  },
];

export const advantagesData1 = [
  {
    id: 'abcAdv1',
    img: CheckIcon,
    name: 'hostingPage.adv1',
  },
  {
    id: 'abcAdv2',
    img: CheckIcon,
    name: 'hostingPage.adv2',
  },
  {
    id: 'abcAdv3',
    img: CheckIcon,
    name: 'hostingPage.adv3',
  },
  {
    id: 'abcAdv4',
    img: CheckIcon,
    name: 'hostingPage.adv4',
  },
];

export const advantagesData2 = [
  {
    id: 'abcAdv5',
    img: CheckIcon,
    name: 'hostingPage.adv5',
  },
  {
    id: 'abcAdv6',
    img: CheckIcon,
    name: 'hostingPage.adv6',
  },
  {
    id: 'abcAdv7',
    img: CheckIcon,
    name: 'hostingPage.adv7',
  },
  {
    id: 'abcAdv8',
    img: CheckIcon,
    name: 'hostingPage.adv8',
  },
];
